import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "ui/lib/utils";

import { X } from "lucide-react";
import React from "react";
import {
  Dialog,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  DialogContent as RadixDialogContent,
} from "ui/components/primitives/dialog";
import {
  ChatBotAIPropsProvider,
  ChatBotAIProviderConfigProps,
} from "./ChatBotAIPropsProvider";
import { ChatBotActionsProvider } from "./ChatBotActionsProvider";
import { ChatBotBody } from "./ChatBotBody";
import { ChatBotHeader } from "./ChatBotHeader";
import { ChatBotInput } from "./ChatBotInput";
import {
  ChatBotUIConfigProvider,
  ChatBotUIProviderConfigProps,
  useChatBotUIConfig,
} from "./ChatBotUIProvider";
import { dialogContainerVariants, useAdjustContentHeight } from "./helpers";

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof RadixDialogContent> {
  className?: string;
  children: React.ReactNode;
}

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  DialogContentProps
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 fixed z-50 flex flex-col w-full gap-4 p-6",
        "dark:bg-background",
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="absolute rounded-full top-2 right-4 shadow-button opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 dark:bg-secondary p-2">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));

interface ChatBotUIDialogComponentsProps {
  children: React.ReactNode;
  onOpenChange?: (value: boolean) => void;
}

const ChatBotUIDialogComponents = ({
  children,
  onOpenChange,
}: ChatBotUIDialogComponentsProps) => {
  const { open, setOpen, variant, overlay } = useChatBotUIConfig();
  const maxHeight = useAdjustContentHeight();

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
        onOpenChange?.(value);
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogPrimitive.Portal>
        {overlay ? <DialogOverlay /> : null}
        <div>
          <DialogContent
            style={{ maxHeight }}
            className={dialogContainerVariants({
              variant,
            })}
          >
            <ChatBotHeader />
            <ChatBotBody />
            <DialogFooter className="relative flex-col sm:flex-col p-4">
              <ChatBotInput />
              <div className="text-xs text-center text-muted-foreground pt-1">
                ArnieAI (Beta) may provide inaccurate information. Please
                contact support{" "}
                <a
                  href="https://airtable.com/shrrrXxt1CKpOkSme"
                  target="_blank"
                  rel="noreferrer"
                  className="text-royal-400"
                >
                  here
                </a>{" "}
                for more help.
              </div>
            </DialogFooter>
          </DialogContent>
        </div>
      </DialogPrimitive.Portal>
    </Dialog>
  );
};

interface ChatBotUIDialogProps extends ChatBotUIProviderConfigProps {
  children: React.ReactNode;
  onOpenChange?: (value: boolean) => void;
  chatProps: ChatBotAIProviderConfigProps;
}

export const ChatBotUIDialog = ({
  children,
  onOpenChange,
  chatProps,
  ...props
}: ChatBotUIDialogProps) => {
  return (
    <ChatBotAIPropsProvider initialState={{ chatProps }}>
      <ChatBotUIConfigProvider initialState={props}>
        <ChatBotActionsProvider>
          <ChatBotUIDialogComponents onOpenChange={onOpenChange}>
            {children}
          </ChatBotUIDialogComponents>
        </ChatBotActionsProvider>
      </ChatBotUIConfigProvider>
    </ChatBotAIPropsProvider>
  );
};
