import { Message, useChat } from "ai/react";
import Cookies from "js-cookie";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChatBotUIDialog } from "ui/components/chat-bot-v2/ChatBotUIDialogComponents";
import { ChatbotFloatingCTA } from "ui/components/chat-bot-v2/ChatbotFloatingCTA";
import { captureEvent } from "ui/lib/captureEvent";
import { isMobile } from "ui/lib/helpers";
import { executeTool, getTool } from "web/tools-v2";

interface Example {
  user: string;
  assistant?: string;
  newUser?: boolean;
}

interface Examples {
  initial: Example[];
  newUser: Example[];
  existingUser: Example[];
}

const initialMessage: Message = {
  role: "assistant",
  content: "Hi! Before we begin, are you new to Ladder?",
  id: "initialMessage",
};

const examples: Examples = {
  initial: [
    {
      user: "Yes, I'm new to Ladder.",
      assistant: "Welcome! How can we help?",
      newUser: true,
    },
    {
      user: "No, I have the app and an account with Ladder.",
      assistant: "That's great! How can we help?",
      newUser: false,
    },
  ],
  newUser: [
    {
      user: "How is Ladder different from other fitness apps?",
      assistant: `Ladder is built for strength training and specifically for busy people who are already motivated to workout. This isn't just a library of random workout content. Ladder gives you a complete workout plan to help you maximize your workouts and achieve real results.\n\nWith Ladder you'll know exactly what workout to do, with a daily workout plan that's programmed by an expert coach and new each week.\n\n✅ Results in less time\n✅ New workouts every week\n✅ A training plan that's always with you\n✅ Real coaching\n\nThe Ladder app is specifically built for progressive strength training, with in-ear coaching, video demonstrations, and precise pacing. It seamlessly integrates with Spotify and Apple Music, so you can use your own playlists during workouts.`,
    },
    {
      user: "Do I need to give a credit card to start a trial?",
      assistant:
        "Nope! Ladder offers a 7 day, completely free trial to find your plan and try as many workouts as you would like on your schedule. Ladder will not ask for a credit card to start a trial. No payment info is collected until AFTER the free trial ends.",
    },
    {
      user: "How do I find the right team/fitness plan?",
      assistant:
        "To find the right team/fitness plan, we recommend taking the [team quiz](https://www.joinladder.com/quiz?utm_medium=organic&utm_source=website&utm_campaign=homepage&utm_term=cs_chatbot). This quiz will connect you with the best coach/team based on your goals and preferences.",
    },
  ],
  existingUser: [
    {
      user: "When are new workouts published?",
      assistant:
        "New workouts are published every week to keep things fresh and challenging. You can expect to see new workouts dropped **every Sunday at 10 PM Eastern time.**",
    },
    {
      user: "Can I track reps and weights in the app?",
      assistant:
        "Yes! We have developed Ladder Journal to help track results so you can actually measure your results and remember what you did last time to level-up.",
    },
    {
      user: "How do I connect my Apple Watch?",
      assistant:
        "Great question! To ensure your Apple Watch is paired correctly, please follow these instructions [here](https://www.notion.so/bengammon1/Ladder-x-Apple-Watch-Syncing-56194f8f489d473e85be9bc0c7a8696b).",
    },
    {
      user: "How do I change teams?",
      assistant:
        'To change teams, follow these steps:\n1. Open the Ladder app and click on the "Teams" tab in the navigation bar.\n2. Browse through the available teams and use the filters to narrow down your choices based on your preferences.\n3. Click on a team card to learn more about it, including the coach, welcome workout, training style, equipment, workout length, skill level, and core movements explained.\n4. Once you\'ve found a team you like, click "Try Team" to switch to that team.\nIf you need any help, our Member Success Team will also be available to assist you in finding your perfect program!',
    },
  ],
};

const BLOCKED_KEY = "USER_BLOCKED_BY_ARNIE";

const AIChatBot: React.FC = () => {
  const autoFocus = useRef(false);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [userIsNew, setUserIsNew] = useState<boolean | null>(null);
  const [blocked, setBlocked] = useState<boolean | null>(
    () => !!Cookies.get(BLOCKED_KEY)
  );
  const [toolInUse, setToolInUse] = useState<string | null>(null);

  useEffect(() => {
    setBlocked(!!Cookies.get(BLOCKED_KEY));
  }, []);

  const chatProps = useChat({
    api: "/api/arnie",
    headers: {
      "Content-Type": "application/json",
    },
    initialMessages: [initialMessage],
    body: {
      toolFilter: ["reportAndBlock", "searchKnowledgeBase"],
    },
    maxToolRoundtrips: 2,
    async onToolCall({ toolCall }) {
      const toolEnv = getTool(toolCall.toolName, "env");
      const toolInUseText = getTool(toolCall.toolName, "toolInUseText");

      if (toolInUseText && typeof toolInUseText === "string") {
        setToolInUse(toolInUseText);
      }

      if (toolEnv !== "server") {
        const result = await executeTool(toolCall.toolName, toolCall.args);
        return result;
      }
    },
    async onFinish(message) {
      setToolInUse(null);
      captureEvent({
        category: "cs_chatbot",
        action: "chatbot_responded",
        modelName: "gpt-4o",
        value: message.content,
      });
    },
  });

  const onSubmit = useCallback(async (value: string, eventProps = {}) => {
    setQuestionsCount((current) => current + 1);

    captureEvent({
      category: "cs_chatbot",
      action: "user_asked_question",
      value,
      ...eventProps,
    });
  }, []);

  const handleExampleClick = useCallback(async (example: Example) => {
    if ("boolean" === typeof example.newUser) {
      setUserIsNew(example.newUser);
    }

    setQuestionsCount((current) => current + 1);

    captureEvent({
      category: "cs_chatbot",
      action: "user_asked_question",
      value: example.user,
      hardcoded: true,
    });

    captureEvent({
      category: "cs_chatbot",
      action: "chatbot_responded",
      value: example.assistant,
      hardcoded: true,
    });

    return { data: example.assistant };
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    // Only autofocus on desktop
    autoFocus.current = !isMobile();
  }, []);

  const memoExamples = useMemo(
    () =>
      questionsCount
        ? userIsNew
          ? examples.newUser
          : examples.existingUser
        : examples.initial,
    [questionsCount, userIsNew]
  );

  return (
    <ChatBotUIDialog
      forcedDisabled={blocked}
      initialPlaceholder={blocked ? "You've been blocked" : "Type a message..."}
      chatProps={chatProps}
      variant="bottomRight"
      toolInUse={toolInUse}
      onExampleClicked={handleExampleClick}
      onSubmit={onSubmit}
      autoFocus={autoFocus.current}
      examples={memoExamples}
      examplesLabel={
        questionsCount ? (
          <span>
            Popular Questions <span className="text-xs animate-bounce">👇</span>
          </span>
        ) : (
          " "
        )
      }
      onOpenChange={(isOpened) => {
        if (isOpened) {
          captureEvent({
            category: "cs_chatbot",
            action: "Chatbot_Opened",
          });
        } else {
          captureEvent({
            category: "cs_chatbot",
            action: "Chatbot_Closed",
          });
        }
      }}
    >
      <ChatbotFloatingCTA />
    </ChatBotUIDialog>
  );
};

export default AIChatBot;
