import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import AIChatBot from "web/components/ai-chat-bot";
import { Footer } from "web/components/footer";
import { HeadContent } from "web/components/head";
import { HomeComponent } from "web/components/home";
import OptimizeLayout from "web/components/layout";
import { ModalsProvider, useModals } from "web/components/modals/container";
import { DesktopQRModal } from "web/components/modals/desktop-qr-modal";
import Nav from "web/components/nav";
import Notice from "web/components/notice/home";
import { ExperimentProvider } from "web/state-containers/experiment";
import { isDev } from "../helpers/misc";

const Content = () => {
  const { query, isReady } = useRouter();
  const { toggleShowQRModal } = useModals();

  useEffect(() => {
    if (!isReady) return;
    if (!query.showAppDownloadQR) return;

    toggleShowQRModal();
  }, [query, isReady, toggleShowQRModal]);

  return (
    <>
      <Notice />
      <Nav showWWOD />
      <div className="h-full max-w-full overflow-hidden">
        <HomeComponent />
        <Footer />
      </div>
    </>
  );
};

const defaultLink = `https://ladder.page.link/?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams&link=https://${
  isDev ? "dev." : ""
}joinladder.com`;

export default function Home() {
  const { query } = useRouter();
  return (
    <>
      <HeadContent title="Ladder | Your Daily Workout Plan" />
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
        }}
      />
      <ExperimentProvider>
        <ModalsProvider>
          <DesktopQRModal
            title="Download the app!"
            description="Scan the QR code start your workout"
            deepLink={defaultLink}
          />
          <Content />
          <AIChatBot />
        </ModalsProvider>
      </ExperimentProvider>
    </>
  );
}

Home.Layout = OptimizeLayout;
