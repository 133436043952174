export const ChatBotHeader = () => {
  return (
    <div className="p-4 rounded-t-xl flex flex-row items-center space-x-1 border-b border-neutral-700 bg-secondary lg:rounded-t-lg shadow-lg h-[50px]">
      {/* circle with p-2 padding */}
      <div className="w-8 h-8 bg-background rounded-full flex center">
        <h3 className="text-base text-muted-foreground heading">🤖</h3>
      </div>
      <span
        style={{
          backgroundImage:
            "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
          backgroundClip: "text",
        }}
        className="text-transparent bg-clip-text"
      >
        Arnie AI
      </span>
      <span className="text-muted-foreground leading-none">(beta)</span>
    </div>
  );
};
